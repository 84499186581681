import React, { useState, useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import view from "../../assets/images/ic_view.svg";
import Skyscrapers from "../../assets/images/Skyscrapers.jpg";
import { Modal } from "react-bootstrap";
import { AppContext } from "../../context/user/AppContext";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../../sharedComponent/Loader";
export const VehicleDetailsList = () => {
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const { customerData, mainClass } = useContext(AppContext);
  const [vehicleList, setvehicleList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentVehicle, setCurrentVehicle] = useState({});
  const data = useLocation().state;
  const [loadDetails, setloadDetails] = useState({
    ...data,
  });
  useEffect(() => {
    getVehicleList();
  }, []);

  const getVehicleList = () => {
    setLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.VEHICLE_LIST,
      JSON.stringify({
        ...customerData,
        load_id: loadDetails.trip_id,
      })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setLoading(false);
        setvehicleList(res.json.vehicle_list);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <main>
      <div className={`vehicle-list  ${mainClass}`} id="cxMain">
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <>
            <h5 className="heading-select-transporter ">VEHICLE LIST</h5>
            <div
              style={{ width: "100%", overflowX: "auto" }}
              className="vehicle-offer-direct-order-table"
            >
              <table style={{ minWidth: "1200px" }}>
                <thead>
                  <tr>
                    <th>
                      <label>S.No</label>
                    </th>
                    <th>
                      <label>Owner Name</label>
                    </th>
                    <th>
                      <label>Vehicle Type</label>
                    </th>
                    <th>
                      <label>Model</label>
                    </th>
                    {/* <th>
                      <label>Load Capacity</label>
                    </th> */}
                    <th>
                      <label>Plate No.</label>
                    </th>
                    <th>
                      <label>Driver Name</label>
                    </th>
                    <th>
                      <label>Nationality</label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {vehicleList &&
                    vehicleList.map((vehicle, index) => {
                      return (
                        <tr className="Rowbgcolor p-2" key={"vehicle" + index}>
                          <td>{index + 1}</td>
                          <td>{vehicle.vehicle_owner}</td>
                          <td>{vehicle.vehicle_type}</td>
                          <td>{vehicle.vehicle_model_no}</td>
                          {/* <td>{vehicle.vehicle_load_capacity}</td> */}
                          <td>{vehicle.plate_number}</td>
                          <td>{vehicle.driver_name}</td>
                          <td>{vehicle.nationality}</td>

                          <td>
                            <Link
                              to="#"
                              onClick={() => {
                                setCurrentVehicle(vehicle);
                                handleShow();
                              }}
                              state={vehicle.vehicle_id}
                            >
                              <img src={view} alt="" />
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id=""
        className="Vehicle-popup-offered"
      >
        <div className="mb-2">
          <Modal.Header closeButton>
            <h4 className="heading-select-transporter ">Vehicle Details</h4>
          </Modal.Header>
        </div>
        <div>
          <section
            className="vehicle-detail-section mb-0 common-body p-3 mt-2"
            style={{
              height: "auto",
              boxShadow: "none",
              borderTop: "1px solid #F6F2EF",
            }}
          >
            <div className="row">
              <div className="col-lg-6">
                <div className="image">
                  <div
                    id="carouselExampleControls"
                    className="carousel slide"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <img
                          src={Skyscrapers}
                          className="d-block "
                          alt={"..."}
                        />
                      </div>
                      {currentVehicle &&
                        currentVehicle.vehicle_images &&
                        currentVehicle.vehicle_images.map((img, index) => {
                          return (
                            <div className="carousel-item">
                              <img
                                width="515px"
                                height="300px"
                                src={
                                  ApiConfig.BASE_URL_FOR_IMAGES +
                                  img.trip_image_url
                                }
                                className="d-block "
                                alt="..."
                              />
                            </div>
                          );
                        })}
                    </div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselExampleControls"
                      data-bs-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselExampleControls"
                      data-bs-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="right-section">
                  <div className="right-top-content d-flex justify-content-between">
                    <div className="title-vehicle">
                      <h4 className="name-vehicle">
                        {currentVehicle.vehicle_owner}
                      </h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-sm-6 left item mt-2">
                      <label>Driver Name</label>
                      <p className="data">
                        {currentVehicle && currentVehicle.driver_name}
                      </p>
                    </div>
                    <div className="col-lg-4 col-sm-6 left item mt-2">
                      <label>Plate Number</label>
                      <p className="data">
                        {currentVehicle && currentVehicle.plate_number}
                      </p>
                    </div>
                    <div className="col-lg-4 middel col-sm-6 item mt-2">
                      <label>Vehicle Type</label>
                      <p className="data">
                        {currentVehicle && currentVehicle.vehicle_type}
                      </p>
                    </div>
                    <div className="col-lg-4 middel col-sm-6 item mt-2">
                      <label>Vehicle Model No</label>
                      <p className="data">
                        {currentVehicle && currentVehicle.vehicle_model_no}
                      </p>
                    </div>
                    <hr />
                    {/* <h5 className="card-heading ">GPS Availability</h5>
                    <div className="col-lg-4 col-sm-6 left item mt-2">
                      <label>Vendor Name</label>
                      <p className="data">
                        {currentVehicle && currentVehicle.vehicle_vendor_name}
                      </p>
                    </div>
                    <div className="col-lg-4 col-sm-6 right item mt-2">
                      <label>Vendor Contact</label>
                      <p className="data">
                        {currentVehicle &&
                          currentVehicle.vehicle_vendor_contact}
                      </p>
                    </div>
                    <div className="col-lg-4 col-sm-6 right item mt-2">
                      <label>Vendor Platform</label>
                      <p className="data">
                        {currentVehicle &&
                          currentVehicle.vehicle_vendor_platform}
                      </p>
                    </div>
                    <div className="col-lg-4 col-sm-6 right item mt-2">
                      <label>Vendor Address</label>
                      <p className="data">
                        {currentVehicle &&
                          currentVehicle.vehicle_vendor_address}
                      </p>
                    </div> */}
                    <hr />
                    <h5 className="card-heading">Driver Details</h5>
                    <div className="col-lg-4 col-sm-6 left item mt-2">
                      <label>Name</label>
                      <p className="data">
                        {currentVehicle && currentVehicle.driver_name}
                      </p>
                    </div>
                    <div className="col-lg-4 col-sm-6 left item mt-2">
                      <label>Nationality</label>
                      <p className="data">
                        {currentVehicle && currentVehicle.nationality}
                      </p>
                    </div>
                    <div className="col-lg-4 col-sm-6 left item mt-2">
                      <label>Driver Licence Number</label>
                      <p className="data">
                        {currentVehicle && currentVehicle.driver_licence_number}
                      </p>
                    </div>
                    {currentVehicle && currentVehicle.trailer_vehicle_type && (
                      <>
                        <hr />
                        <h5 className="card-heading">Trailer Details</h5>
                        <div className="col-lg-4 col-sm-6 left item mt-2">
                          <label>Trailer Vehicle Type</label>
                          <p className="data">
                            {currentVehicle &&
                              currentVehicle.trailer_vehicle_type}
                          </p>
                        </div>
                        <div className="col-lg-4 col-sm-6 left item mt-2">
                          <label>Trailer Plate No</label>
                          <p className="data">
                            {currentVehicle && currentVehicle.trailer_plate_no}
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Modal>
    </main>
  );
};
