import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import star_highlighted from "../../assets/images/vehiclesDetails/star_highlighted.svg";
import star_grey from "../../assets/images/vehiclesDetails/ic_star_grey.svg";
import user from "../../assets/images/Dashboard/user_3.svg";
import { AppContext } from "../../context/user/AppContext";
import ApiConfig from "../../api/ApiConfig";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import Loader from "../../sharedComponent/Loader";

const SelectTransporter = () => {
  const { customerData, mainClass } = useContext(AppContext);
  const goods = useLocation().state;
  const [data, setData] = useState({ ...goods });
  const [transpoterLists, setTranspoterLists] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    gettranspoterList();
  }, []);
  const gettranspoterList = () => {
    setLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.TRANSPOTER_LIST,
      JSON.stringify({
        ...customerData,
        load_id: data.trip_id,
      })
    ).then((res) => {
      
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      setLoading(false);
      if (res.json.result) setTranspoterLists(res.json.transporter_list);
    });
  };

  useEffect(() => {
  }, [transpoterLists]);

  return (
    <main className={`vehicle-list  ${mainClass}`} id="cxMain">
      <h5 className="heading-select-transporter ">SELECT TRANSPORTER</h5>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <>
          {transpoterLists &&
            transpoterLists.length &&
            transpoterLists.map((transporter, index) => {
              return (
                <Link
                  to="/VehicleDetailsList"
                  state={data}
                  className="text-decoration-none"
                  key={"transporter" + index}
                >
                  <div className="top-content common-body mt-3 select-transporter">
                    <div className="driver-profile-details d-flex align-items-center">
                      <div className="profile-img-transporter">
                        <img
                          src={
                            transporter.user_profile_pic
                              ? ApiConfig.BASE_URL_FOR_IMAGES +
                                transporter.user_profile_pic
                              : user
                          }
                          alt="pic"
                          className="rounded-circle me-2"
                          width="42"
                          height="42"
                        />
                      </div>
                      <div className="driver-name">
                        <div className="title-driver">
                          <h4 className="name-driver">
                            {transporter.user_name}
                          </h4>
                        </div>
                        <div className="rating">
                          <img src={star_highlighted} alt="" />
                          <img src={star_highlighted} alt="" />
                          <img src={star_highlighted} alt="" />
                          <img src={star_highlighted} alt="" />
                          <img src={star_grey} alt="" />
                          <span>{transporter.rating}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
        </>
      )}
    </main>
  );
};

export default SelectTransporter;
