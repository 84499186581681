import { City } from 'country-state-city';
import { Country } from 'country-state-city';
import { useEffect, useState } from 'react';
import Select from 'react-select'
export default function CitySelect(props) {
    const [options, setOptions] = useState([])
    let { setCity, country, city } = props;
    const customStyles = {
        input: (provided, state) => ({
            ...provided,
            gridTemplateColumns: '0px 100%'
        }),
    };
    useEffect(() => {
        country = Country.getAllCountries(country).filter(cnt => country === cnt.name || country === cnt.isoCode)[0];
        if (country) {
            country = country.isoCode
            setOptions(City.getCitiesOfCountry(country).map(city => {
                return ({ value: city.name, label: city.name })
            }))
        }

    }, [country])
    return (
        <div className="form-group mt-4">
            <label htmlFor="exampleInputEmail1">City</label>
            

            <Select options={options} onChange={setCity} {...props} styles={customStyles}
                value={
                    options.filter(option => {
                        return option.label === city
                    })
                } />
        </div>
    )
}

