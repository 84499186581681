import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/user/AppContext";
import { multipartPostCall } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const navigate = useNavigate();

  const { mainClassB, customerData } = useContext(AppContext);
  const [changePasswordData, setChangePasswordData] = useState({
    current_password: "",
    new_password: "",
    confirm_new_password: "",
  });
  const [errMsg, setErrMsg] = useState({
    current_password: "",
    new_password: "",
    confirm_new_password: "",
  });

  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const changePassword = () => {
    if (
      !changePasswordData.current_password ||
      !changePasswordData.new_password ||
      !changePasswordData.confirm_new_password
    ) {
      if (changePasswordData.current_password === "") {
        setErrMsg({
          ...errMsg,
          current_password: "Current Password is required",
        });
        return;
      }
      if (changePasswordData.new_password === "") {
        setErrMsg({ ...errMsg, new_password: "New Password is required" });
        return;
      }
    }
    if (changePasswordData.confirm_new_password === "") {
      setErrMsg({
        ...errMsg,
        new_password: "Confirm New Password is required",
      });
      return;
    }
    if (isPasswordValid) {
      swal({
        icon: "error",
        title: "Invalid password format",
        text: " Password must be at least 8 characters long, contain a lowercase letter,an uppercase letter, and a number or special character.",
      });
      return;
    } else {
      let details = JSON.stringify({
        customer_id: customerData.customer_id,
        api_key: customerData.api_key,
        user_id: customerData.user_id,
        current_password: changePasswordData.current_password,
        new_password: changePasswordData.new_password,
      });

      multipartPostCall(ApiConfig.CHANGE_PASSWORD, details)
        .then((res) => {
          if (res.json.message === 
            "Invalid user authentication,Please try to relogin with exact credentials.") {
              localStorage.clear();
              window.open("/login", "_self");
          }
          console.log(res);
          if (res.result) {
            swal(res.message);
            navigate("/Dashboard");
          } else {
            swal(res.message);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const validatePassword = () => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!@#$%^&*])(?=.{8,})/;

    if (passwordRegex.test(changePasswordData.new_password)) {
      setIsPasswordValid(false);
    } else {
      setIsPasswordValid(true);
    }
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setChangePasswordData({
      ...changePasswordData,
      new_password: newPassword,
    });
    validatePassword(newPassword);
  };
  return (
    <>
      <main className={` main-section-reports  ${mainClassB}`} id="cxMainB">
        {/* Report tabs */}

        <section className="report-body d-flex justify-content-center">
          <div className="reports-body-section ">
            <h4 className="reports-card-heading">Change Password</h4>
            <hr />
            <div className="form-group mt-4">
              <label htmlFor="exampleInputEmail1">Enter Current Password</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setErrMsg({ ...errMsg, current_password: "" });
                  setChangePasswordData({
                    ...changePasswordData,
                    current_password: e.target.value,
                  });
                }}
              />
              {errMsg.current_password.length > 0 && (
                <div className="text-center text-danger">
                  <span> {errMsg.current_password}</span>
                </div>
              )}
            </div>
            <div className="form-group mt-4">
              <label htmlFor="exampleInputEmail1">Enter New Password</label>
              <input
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                onChange={handlePasswordChange}
              />
              {isPasswordValid && (
                <h6 className="my-2" style={{ color: "red" }}>
                  Password must be at least 8 characters long
                  <br /> Password must contain a lowercase letter, an uppercase
                  letter
                  <br />
                  Password must contain number or special character.
                </h6>
              )}
              {errMsg.new_password.length > 0 && (
                <div className="text-center text-danger">
                  <span> {errMsg.new_password}</span>
                </div>
              )}
            </div>
            <div className="form-group mt-4">
              <label htmlFor="exampleInputEmail1">Confirm New Password</label>
              <input
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                onChange={(e) => {
                  setErrMsg({ ...errMsg, confirm_new_password: "" });
                  setChangePasswordData({
                    ...changePasswordData,
                    confirm_new_password: e.target.value,
                  });
                }}
              />
              {errMsg.confirm_new_password.length > 0 && (
                <div className="text-center text-danger">
                  <span> {errMsg.confirm_new_password}</span>
                </div>
              )}
            </div>
            <div className="d-flex justify-content-center my-4">
              <button className="report-btn" onClick={changePassword}>
                Submit
              </button>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
export default ChangePassword;
