import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../context/user/AppContext";
import ApiConfig from "../../api/ApiConfig";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import Loader from "../../sharedComponent/Loader";
import { useLocation, useParams } from "react-router-dom";

const OrderConfirmationDetails = () => {
  const params = useParams();
  let id = params.id
  const data = useLocation().state;
  const [order, setOrder] = useState({ ...data });
  const [totalPrice, setTotalPrice] = useState(0);
  const [bidCommission, setBidCommission] = useState(0);
  const [tripVehicles, setTripVehicles] = useState([{}]);
  const { customerData, mainClass } = useContext(AppContext);
  // const [OrderConfirmationDetails, setOrderConfirmationDetails] = useState({
  //   ...order,
  //   load_details: {},
  //   transporter_details: [{}],
  // });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getOrderCOnfirmDetails();
  }, []);
  const getOrderCOnfirmDetails = () => {
    setLoading(true);

    postWithAuthCallWithErrorResponse(
      ApiConfig.ORDER_CONFIRMATION_DETAILS,
      JSON.stringify({
        ...customerData,
        load_id: order.trip_id,
      })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      setLoading(false);
      if (res.json.result) {
          setOrder(res.json.load_details);
          setTotalPrice(res.json.total_price_value);
          setTripVehicles(res.json.trip_vehicles);
          setBidCommission(res.json.trip_bid?.trip_bid_commission);
        }
    });
  };

  return (
    <main className={`vehicle-list ${mainClass}`} id="cxMain">
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <section className="common-body details">
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <label htmlFor="">Reference Number (Offer Freight)</label>
              <p>{order && order.trip_reference_no}</p>
            </div>
            <div className="col-md-4 col-sm-6">
              <label htmlFor="">Operation Number</label>
              <p>{order && order.trip_operation_no}</p>
            </div>
            <div className="col-md-4 col-sm-6">
              <label htmlFor="">Company Name (Shipper)</label>
              <p>{order && order.shipper}</p>
            </div>
            <div className="col-md-4 col-sm-6">
              <label htmlFor="">Cargo Type</label>
              <p>{order && order.cargo_type}</p>
            </div>
            {bidCommission && <div className="col-md-4 col-sm-6">
              <label htmlFor="">Bid Commission</label>
              <p>{bidCommission && bidCommission}%</p>
            </div>}
            <div className="col-md-4 col-sm-6">
              <label htmlFor="">Quantity</label>
              <p>{order && order.total_cargo_quantity}</p>
            </div>
            <div className="col-md-4 col-sm-6">
              <label htmlFor="">Unit Price</label>
              <p>{order && order.unit_price}</p>
            </div>
            {order.trip_bid_commission && order.trip_bid_commission ? 
            <div className="col-md-4 col-sm-6">
              <label htmlFor="">Bid Commission</label>
              <p>{order.trip_bid_commission && order.trip_bid_commission}%</p>
            </div> :
            <div className="col-md-4 col-sm-6">
            <label htmlFor="">Total Price</label>
            <p>{totalPrice && totalPrice}</p>
            </div>
            }
            {order.trip_bid_commission && order.trip_bid_commission ? 
            <div className="col-md-4 col-sm-6">
              <label htmlFor="">Total Price</label>
              <p>{totalPrice && order.trip_bid_commission && 
              (totalPrice + totalPrice*order.trip_bid_commission/100 )}</p>
            </div> :
            <div className="col-md-4 col-sm-6">
            <label htmlFor="">Total Price</label>
            <p>{totalPrice && totalPrice}</p>
            </div>
            }
            <div className="col-md-4 col-sm-6">
              <label htmlFor="">Loading Place</label>
              <p>{order && order.loading_place}</p>
            </div>
            <div className="col-md-4 col-sm-6">
              <label htmlFor="">Expected Loading Date</label>
              <p>{order && order.loading_date}</p>
            </div>
            <div className="col-md-4 col-sm-6">
              <label htmlFor="">Exp. Arrival Time At The Destination</label>
              <p>
                {order && order.arrival_date}{" "}
                {order && order.arrival_time}
              </p>
            </div>
          </div>
          <span className="confirm-detail-heading mt-4 mb-4">
            Transporter Details
          </span>
          <div style={{ width: "100%", overflow: "auto" }}>
            <table className=" " style={{ minWidth: "700px" }}>
              <thead>
                <tr>
                  <td>
                    <label htmlFor="">Transporter’s Name</label>
                  </td>
                  <td>
                    <label htmlFor="">Loading Truck Plate Number</label>
                  </td>
                  <td>
                    <label htmlFor="">Vehicle Cargo Qty</label>
                  </td>
                  <td>
                    <label htmlFor="">Driver Name</label>
                  </td>
                  <td>
                    <label htmlFor="">Driver Cell Phone</label>
                  </td>
                  <td>
                    <label htmlFor="">Trip Status</label>
                  </td>
                </tr>
              </thead>
              <tbody>
                {tripVehicles.map(
                  (item, index) => {
                    return (
                      <tr className="t-row-confirm mb-3" key={"item" + index}>
                        <td>{item.transporter_name}</td>
                        <td>
                          <p>{item.vehicle_number}</p>{" "}
                        </td>
                        <td>
                          <p>{item.vehicle_cargo_quantity}</p>{" "}
                        </td>
                        <td>
                          <p>{item.user_name}</p>{" "}
                        </td>
                        <td>
                          <p>{item.driver_phone_number}</p>{" "}
                        </td>
                        <td>
                          <p>{item.trip_status}</p>{" "}
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        </section>
      )}
    </main>
  );
};

export default OrderConfirmationDetails;
