import React, { useContext, useEffect, useState } from "react";
import ApiConfig from "../api/ApiConfig";
import { postWithAuthCallWithErrorResponse } from "../api/ApiServices";
import { AppContext } from "../context/user/AppContext";

const TransporterRates = () => {
  const { mainClassB, customerData } = useContext(AppContext);
  const [rateChartList, setRateChartList] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    RateChartList();
  }, []);
  const RateChartList = () => {
    setLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.RATE_CHART_LIST,
      JSON.stringify({ ...customerData })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      setRateChartList(res.json.rate_chart_list);
      setLoading(false);
    });
  };
  return (
    <main className={`contract-main-section ${mainClassB}`} id="cxMainB">
      <div className="rates-main " style={{ margin: "0 20px" }}>
        <div className="transporter">
          <div className="heading-top">
            <input
              type="text"
              className="form-control mb-3"
              placeholder="Search"
            />
          </div>
          <div className="offerLoadsMainLists">
            <div className="mainLoadTable" style={{ overflow: "auto" }}>
              <table className="dataList" style={{ minWidth: "900px" }}>
                <thead>
                  <tr>
                    <th>
                      <label>S.No.</label>
                    </th>
                    <th>
                      <label>Rate type /Category</label>
                    </th>
                    <th>
                      <label>Currency</label>
                    </th>
                    <th>
                      <label>20 ft(teu)</label>
                    </th>

                    <th>
                      <label>40 ft(feu)</label>
                    </th>
                    <th className="">
                      <label>Break bulk(freight tone)</label>
                    </th>
                    <th>
                      <label>Bulk (tone)</label>
                    </th>
                    <th>
                      <label>RORO(Unit)</label>
                    </th>
                    <th>
                      <label>Other</label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rateChartList &&
                    rateChartList.map((rateListItem, index) => {
                      return (
                        <tr className="Rowbgcolor" key={"rateListItem" + index}>
                          <td>
                            <label>{index + 1}</label>
                          </td>
                          <td>
                            <label>{rateListItem.rate_chart_category}</label>
                          </td>
                          <td>
                            <label>{rateListItem.currency_code}</label>
                          </td>
                          <td>
                            <label>{rateChartList.rate_chart_20ft}</label>
                          </td>

                          <td>
                            <label>{rateListItem.rate_chart_40ft}</label>
                          </td>

                          <td>
                            <label>{rateListItem.rate_chart_break_bulk}</label>
                          </td>
                          <td>
                            <label>{rateListItem.rate_chart_bulk}</label>
                          </td>
                          <td>
                            <label>{rateListItem.rate_chart_roro}</label>
                          </td>
                          <td>
                            <label>{rateListItem.rate_chart_other}</label>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default TransporterRates;
